import React from "react"
import Styled from "styled-components"
import Container from "./Container"
import { Text } from "deskie-ui"

const StyledCredits = Styled(Container)`
padding-top: 2rem;
padding-bottom: 2rem;

  h2 {
    color: ${props => props.theme.colors.primary};
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 2rem 0 1rem;
    font-size: 1.5rem;
  }

  .credits-card {
    padding: 2rem;
    box-shadow: ${props => props.theme.elevation[2]};
    background: #ffffff;
    padding: 2rem;
    border-radius: 1.5rem;
  }
`

const Credits = () => {
  return (
    <StyledCredits>
      <h2>Reserveren op basis van een creditsysteem. </h2>
      <div className="credits-card">
        <Text p>
          Deskie is gebaseerd op een creditsysteem. Afhankelijk per organisatie
          ontvangt de gebruiker een x-aantal credits per week om te kunnen
          reserveren. Dit systeem is bedoeld om het reserveren van werkplekken
          eerlijk te laten verlopen. Want zijn je credits op? Dan moet je
          wachten tot je reservering voldaan is en je credit weer vrij komt.
        </Text>
      </div>
    </StyledCredits>
  )
}

export default Credits
