import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Container from "./Container"

const StyledScreenshots = styled.div`
  padding-top: 6rem;
  padding-bottom: 2rem;
  overflow: hidden;

  .container {
    width: min(72rem, 100%);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 0;

    & > *:nth-child(even) {
      transform: rotate(-10deg);
    }
    & > *:nth-child(odd) {
      transform: rotate(10deg);
    }

    & > * {
      border-radius: 2rem;
      box-shadow: 5px 5px 10px 0 rgba(17, 20, 45, 0.25);
      user-select: none;

      img {
        border-radius: 2rem;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 48rem) {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-left: -3rem;
    margin-right: -3rem;

    .container {
      width: 120vw;
      flex-shrink: 0;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      & > *:nth-child(odd) {
        display: none;
      }

      & > *:nth-child(4) {
        transform: rotate(-10deg);
      }
    }
  }

  @media screen and (max-width: 40rem) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`

type Data = {
  screenshot1: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  screenshot2: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  screenshot3: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  screenshot4: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

function Screenshots() {
  const {
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
  } = useStaticQuery<Data>(graphql`
    query ScreenshotsQuery {
      screenshot1: file(relativePath: { eq: "assets/screenshot-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot2: file(relativePath: { eq: "assets/screenshot-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot3: file(relativePath: { eq: "assets/screenshot-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot4: file(relativePath: { eq: "assets/screenshot-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledScreenshots className="screenshots">
      <Container>
        <Img fluid={screenshot1.childImageSharp.fluid} />
        <Img fluid={screenshot2.childImageSharp.fluid} />
        <Img fluid={screenshot3.childImageSharp.fluid} />
        <Img fluid={screenshot4.childImageSharp.fluid} />
      </Container>
    </StyledScreenshots>
  )
}

export default Screenshots
