import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "./Container"
import Collapse from "./Collapse"
import { KeyboardArrowDown } from "@styled-icons/material-outlined"
import { Button, Text } from "deskie-ui"

const questions: { question: string; answer: JSX.Element | string }[] = [
  {
    question: "Hoe snel kan ik met Deskie beginnen?",
    answer: (
      <>
        Huh, ben je nog niet gestart? Je kunt direct beginnen.{" "}
        <Link to="aan-de-slag">Meld je aan</Link> en start jouw Deskie.
      </>
    ),
  },
  {
    question: "Moet ik een apart gebruikersaccount aanmaken voor Deskie?",
    answer:
      "Nee hoor. Je logt gewoon in met je Microsoft 365 account. Deskie is Single-sign-on met jouw systemen.",
  },
  {
    question: "Op welke besturingssystemen werkt Deskie?",
    answer:
      "Je downloadt Deskie als progressive webapp in je browser. Daarna kun je het gebruiken op al je devices.",
  },
  {
    question: "Hoe richt ik Deskie in?",
    answer:
      "Easy. Start met het aantal locaties. Verdeel je locaties in het aantal te boeken ruimtes en voeg hier jouw business rules aan toe. Vervolgens is het een kwestie van aanmelden en je kan van start.",
  },
  {
    question: "Hoe bepaal ik mijn business rules?",
    answer:
      "Die bepaal jij zelf. Jij geeft namelijk per locatie en/of ruimte aan hoeveel mensen er maximaal mogen reserveren. Het is belangrijk dat de 1,5 meter gewaarborgd kan worden.",
  },
  {
    question: "Moet ik een uitgebreide plattegrond maken en uploaden?",
    answer:
      "Nope. Niet nodig. Deskie werkt niet op basis van plattegronden, maar op basis van business rules. Jij bepaalt hoeveel mensen er per ruimte mogen zijn. Vervolgens kiezen medewerkers op locatie hun eigen werkplek.",
  },
  {
    question: "Kan ik reserveringen van collega’s zien?",
    answer:
      "Ja, dat kan zeker. Elke reservering is zichtbaar, zodat je weet welke collega wanneer op kantoor is. Wel zo handig als er samengewerkt moet worden.",
  },
  {
    question: "Hoe is het gesteld met de privacy van medewerkers?",
    answer:
      "Privacy vinden wij belangrijk. Daarom voldoet Deskie voor de volle 100% aan de AVG wetgeving.",
  },
  {
    question:
      "Kan ik het aantal gebruikers tijdens het gebruik van Deskie aanpassen?",
    answer:
      "Yes, dat kan. Je rekent maandelijks af op het aantal actieve gebruikers.",
  },
  {
    question: "In welke talen is Deskie beschikbaar?",
    answer: "Deskie is zowel in het Nederlands als Engels beschikbaar.",
  },
  {
    question: "Hoe zeg ik Deskie op?",
    answer: (
      <>
        Zodra je gebruik maakt van het maandelijkse abonnement kun je Deskie
        voor de eerst volgende maand opzeggen. Neem hiervoor{" "}
        <Link to="contact">contact</Link> op met Deskie Support.
      </>
    ),
  },
]

const StyledFaq = styled.div`
  padding: 3rem 0;

  h4 {
    color: ${props => props.theme.colors.primary};
    margin: 0 0 2rem;
  }

  .faq-list {
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02),
      0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 36rem) {
      padding: 0.5rem 0;
    }
  }

  .faq-question {
    cursor: pointer;
    padding: 2rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 36rem) {
      font-size: 1rem;
      padding: 1rem 1.5rem;
    }
  }

  .faq-container.active .faq-question-icon svg {
    transform: rotate(180deg);
  }

  .faq-question-icon {
    width: 1.75rem;
    margin-left: auto;
    flex-shrink: 0;
    padding-left: 0.5rem;

    svg {
      transition: 0.15s ease;
      width: 100%;
      height: auto;
    }
  }

  .faq-container {
    border-bottom: 1px solid ${props => props.theme.colors.border};

    &:last-child {
      border-bottom: 0;
    }
  }

  .faq-answer {
    padding: 0 2rem 2rem;

    @media screen and (max-width: 36rem) {
      padding: 0 1.5rem 1rem;
    }

    a {
      color: ${props => props.theme.colors.primary};

      &:hover {
        text-decoration: none;
      }
    }
  }

  .faq-footer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    a {
      text-decoration: none;
    }
  }
`

type FaqProps = {
  showTitle?: boolean
  limit?: number
}

function Faq({ showTitle = false, limit }: FaqProps) {
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(
    undefined
  )

  return (
    <StyledFaq className="faq">
      <Container>
        {showTitle && (
          <Text h1 as="h4">
            Veelgestelde vragen
          </Text>
        )}
        <div className="faq-list">
          {questions
            .filter((_, i) => (limit && i >= limit ? false : true))
            .map((item, index) => (
              <div
                className={`faq-container ${
                  index === currentIndex ? "active" : ""
                }`}
                key={index}
              >
                <Text
                  as="div"
                  h2
                  className="faq-question"
                  onClick={() =>
                    setCurrentIndex(
                      typeof currentIndex === "undefined"
                        ? index
                        : index === currentIndex
                        ? undefined
                        : index
                    )
                  }
                >
                  {item.question}
                  <span className="faq-question-icon">
                    <KeyboardArrowDown />
                  </span>
                </Text>

                <Collapse open={index === currentIndex}>
                  <Text appearance="secondary" p className="faq-answer">
                    {item.answer}
                  </Text>
                </Collapse>
              </div>
            ))}
        </div>
        {limit && (
          <div className="faq-footer">
            {/* @ts-ignore */}
            <Button as={Link} to="/faq">
              Bekijk alle vragen
            </Button>
          </div>
        )}
      </Container>
    </StyledFaq>
  )
}

export default Faq
