import React from "react"
import Styled from "styled-components"
import BenefitsCard from "./BenefitsCard"
import Container from "./Container"
import { Speed, Today, Workspaces } from "@styled-icons/material-outlined"

const StyledBenefits = Styled(Container)`
  h2 {
    color: ${props => props.theme.colors.primary};
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 2rem 0 0;
    font-size: 1.5rem;
  }

  .benefits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 2rem;
    padding: 2rem 0;
  }
`

const Benefits = () => {
  return (
    <StyledBenefits>
      <h2>Werkplek reserveren. Makkie met Deskie.</h2>
      <div className="benefits">
        <BenefitsCard
          title="Direct te gebruiken."
          description="Deskie kun je direct gebruiken. Geen gedoe met plattegronden en inloggegevens. Nee, direct starten. Dat beloven we. Log in met je Microsoft 365 account, maak je locaties en ruimtes aan en focus op wat écht belangrijk is: je werk."
          backgroundColor="#965beb"
        >
          <Speed />
        </BenefitsCard>
        <BenefitsCard
          title="Super simpel."
          description="Deskie maakt een werkplek reserveren super simpel. Kies je dag, tijdstip, locatie en ruimte en maak je reservering definitief."
          backgroundColor="#5b6feb"
        >
          <Today />
        </BenefitsCard>
        <BenefitsCard
          title="Overzicht to the max."
          description="Zie in een overzicht hoeveel werkplekken nog te reserveren zijn, maar ook wie wanneer op kantoor is geweest. Handig als er onverhoopt een bron- en contactonderzoek moet plaatsvinden."
          backgroundColor="#b458e0"
        >
          <Workspaces />
        </BenefitsCard>
      </div>
    </StyledBenefits>
  )
}

export default Benefits
