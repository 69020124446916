import React, { PropsWithChildren } from "react"
import styled from "styled-components"

type BenefitsCardProps = {
  title: string
  description: string
  backgroundColor: string
}

export const StyledBenefitsCard = styled.div<{ backgroundColor: string }>`
  padding: 2rem;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  border-radius: 1.5rem;
  position: relative;

  & > * {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: ${props => props.backgroundColor};
    opacity: 0.5;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    transform: scale(0.9) translateY(8.5%);
  }

  .title {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    color: #ffffff;
  }

  .content {
    text-align: center;
    font-weight: 500;
    line-height: 1.75;
  }

  svg {
    height: 3rem;
    width: 3rem;
    align-self: center;
    margin: 1.5rem;
  }
`

function BenefitsCard({
  children,
  title,
  description,
  backgroundColor,
}: PropsWithChildren<BenefitsCardProps>) {
  return (
    <StyledBenefitsCard backgroundColor={backgroundColor}>
      <div>
        <span className="title">{title}</span>
        {children}
        <span className="content">{description}</span>
      </div>
    </StyledBenefitsCard>
  )
}

export default BenefitsCard
