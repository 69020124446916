import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Container from "./Container"

const StyledHeader = styled.header`
  margin-top: -6rem;

  .header-content {
    padding: 1rem 0 0;
  }

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    font-family: "Poppins", sans-serif;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: 300;
    line-height: 1.75;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem;
    padding-right: 2rem;
  }

  a {
    border-radius: 16px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    color: #6c5dd3;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    padding: 1rem 2rem;
    transition: 0.1s ease;
    display: inline-flex;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    }
  }

  @media screen and (max-width: 50rem) {
    overflow: hidden;

    .header-content {
      padding-top: 8rem;
      text-align: center;
    }

    p {
      padding-right: 0;
    }

    .header-image {
      margin-top: 4rem;
      margin-bottom: -8rem;
      display: flex;
      justify-content: center;

      & > * {
        width: min(250px, 100%);
        border-radius: 2rem;

        box-shadow: 0 0 19px 0 rgba(17, 20, 45, 0.5);
        user-select: none;

        img {
          border-radius: 2rem;

          overflow: hidden;
        }
      }
    }
  }

  @media screen and (min-width: 50rem) {
    .container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      gap: 2rem;
      align-items: center;
    }

    .header-image {
      position: relative;
      display: flex;
      justify-content: center;
      padding-top: 2rem;

      &:after {
        content: "";
        background-image: url("/paths.svg");
        background-size: auto 110%;
        background-position: 0% center;
        transform: translateX(-8%);
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      & > * {
        width: min(250px, 100%);
        position: relative;
        z-index: 2;
        transform: rotate(15deg) scale(1.05) translateX(-10%);
        border-radius: 2rem;
        box-shadow: 0 0 19px 0 rgba(17, 20, 45, 0.5);
      }

      img {
        border-radius: 1rem;
        overflow: hidden;
      }
    }
  }
`

type Data = {
  screenshot: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

function Header() {
  const { screenshot } = useStaticQuery<Data>(graphql`
    query HeaderQuery {
      screenshot: file(relativePath: { eq: "assets/screenshot-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledHeader className="header">
      <Container>
        <div className="header-content">
          <h1>Reserveer makkelijk en snel je werkplek met Deskie.</h1>
          <p>
            Een veilige werkomgeving creëer je met Deskie. Log direct in en
            reserveer je werkplek. Super simpel en makkelijk in gebruik.
          </p>
          <Link to="/aan-de-slag">Probeer de eerste 30 dagen gratis</Link>
        </div>
        <div className="header-image">
          <Img
            alt="Werkplek reserveren"
            fluid={screenshot.childImageSharp.fluid}
          />
        </div>
      </Container>
    </StyledHeader>
  )
}

export default Header
