import React from "react"
import styled from "styled-components"
import { Check } from "@styled-icons/material-outlined"
import { Link } from "gatsby"

type PricingPlanProps = {
  title: string
  bullets?: string[]
  cost?: string
  text?: string
  highlighted?: boolean
  isTryable?: boolean
  showNote?: boolean
  value: string
}

const StyledPricingPlan = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;

  .plan-highlight {
    height: 4.5rem;
    color: #fff;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 1.5rem 1.5rem 0 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: -2rem;
  }

  .plan-inner {
    margin-top: ${props => (props.highlighted ? "0" : "2.5rem")};
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white;
    border-radius: 1.5rem;
    height: 100%;

    box-shadow: 0 0.8px 2.1px rgba(0, 0, 0, 0.02),
      0 2.7px 6.9px rgba(0, 0, 0, 0.03), 0 12px 31px rgba(0, 0, 0, 0.05);

    .plan-title {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0;
    }

    .plan-bullets {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        margin: 1rem 0rem;
        color: ${props => props.theme.colors.accent9};
        font-size: 0.875rem;

        svg {
          color: ${props => props.theme.colors.black};
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }

    .plan-text {
      color: ${props => props.theme.colors.accent9};
      font-size: 0.875rem;
      margin: 1rem 0rem;
    }

    .plan-footer {
      margin-top: auto;
      display: flex;
      flex-direction: column;
    }

    .plan-button {
      -webkit-appearance: none;
      border: 0;
      font: inherit;
      height: 3.5rem;
      border-radius: 1rem;
      padding: 0 1.25rem;
      background-color: #6c5dd3;
      color: #ffffff;
      display: inline-flex;
      font-weight: 500;
      font-size: 0.875rem;
      user-select: none;
      text-shadow: none;
      justify-content: center;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      transition: 0.1s ease;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      text-decoration: none;
    }

    .plan-cost {
      color: ${props => props.theme.colors.primary};
      font-family: "Poppins", sans-serif;
      text-align: center;
      margin: 2rem 0rem;
      font-weight: 500;
      font-size: 1.125rem;
    }

    .plan-note {
      color: ${props => props.theme.colors.accent9};
      font-size: 0.75rem;
      text-align: center;
      margin-top: -2rem;
      margin-bottom: 2rem;
    }
  }
`

function PricingPlan({
  title,
  bullets,
  cost,
  highlighted = false,
  isTryable = true,
  showNote = false,
  text,
  value,
}: PricingPlanProps) {
  return (
    <StyledPricingPlan className="plan" highlighted={highlighted}>
      {highlighted && <div className="plan-highlight">Eerste maand gratis</div>}
      <div className="plan-inner">
        <h3 className="plan-title">{title}</h3>
        {bullets && (
          <ul className="plan-bullets">
            {bullets.map((item, index) => (
              <li key={index}>
                <Check />
                {item}
              </li>
            ))}
          </ul>
        )}
        {text && <p className="plan-text">{text}</p>}
        <div className="plan-footer">
          {cost && <span className="plan-cost">{cost}</span>}
          {showNote && (
            <span className="plan-note">
              per gebruiker
              <br />
              <br />€ 2,99 maandelijks opzegbaar
            </span>
          )}
          {isTryable ? (
            <Link className="plan-button" to={`/aan-de-slag?plan=${value}`}>
              Aan de slag
            </Link>
          ) : (
            <Link className="plan-button plan-button--ghost" to={`/contact`}>
              Contact
            </Link>
          )}
        </div>
      </div>
    </StyledPricingPlan>
  )
}

export default PricingPlan
