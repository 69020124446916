import React from "react"
import styled from "styled-components"
import PricingPlan from "./PricingPlan"
import Container from "./Container"

const StyledPricing = styled.div`
  padding: 2rem 0;
  margin-top: 2rem;

  .pricing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 2rem;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 0 0 2rem;
    font-size: 1.5rem;
  }
`

function Pricing() {
  return (
    <Container>
      <StyledPricing>
        <h3>Een Deskie die past bij jouw situatie.</h3>
        <div className="pricing">
          <PricingPlan
            value="Free"
            title="Starter"
            bullets={["0 - 10 gebruikers"]}
            cost="Gratis"
          />
          <PricingPlan
            value="Premium"
            title="Professional"
            highlighted
            bullets={["Vanaf 10 gebruikers"]}
            cost="€ 1,99 per maand"
            showNote
          />
          <PricingPlan
            title="Enterprise"
            value="Business"
            text="Meer dan 500 gebruikers? Neem contact op met Deskie Support."
            // cost="Aangepaste prijs"
            isTryable={false}
          />
        </div>
      </StyledPricing>
    </Container>
  )
}

export default Pricing
