import React, { HTMLAttributes, useRef, useLayoutEffect, useState } from "react"

type CollapseProps = HTMLAttributes<unknown> & {
  lazy?: boolean
  instant?: boolean
  open?: boolean
  onComplete?: () => void
  transitionDuration?: string
  transitionTimingFunction?: string
}

function Collapse({
  children,
  instant,
  lazy,
  onComplete,
  open,
  transitionDuration = "400ms",
  transitionTimingFunction = "ease-in-out",
  ...restProps
}: CollapseProps) {
  const transition = `height ${transitionDuration} ${transitionTimingFunction}`
  const [renderChildren, setRenderChildren] = useState(lazy ? open : true)
  const ref = useRef<HTMLDivElement>(null)
  const firstRender = useRef(true)

  function openCollapse() {
    const node = ref.current
    if (node) {
      requestAnimationFrame(() => {
        node.style.height = node.scrollHeight + "px"
      })
    }
  }

  function closeCollapse() {
    const node = ref.current
    if (node) {
      requestAnimationFrame(() => {
        node.style.height = node.offsetHeight + "px"
        node.style.overflow = "hidden"
        requestAnimationFrame(() => {
          if (node) {
            node.style.height = "0px"
          }
        })
      })
    }
  }

  useLayoutEffect(() => {
    if (lazy) {
      if (open) {
        if (renderChildren) {
          openCollapse()
        } else {
          setRenderChildren(true)
        }
      } else {
        closeCollapse()
      }
    } else {
      if (open) {
        openCollapse()
      } else {
        closeCollapse()
      }
    }
  }, [open])

  useLayoutEffect(() => {
    const node = ref.current
    function handleComplete() {
      if (node) {
        node.style.overflow = open ? "initial" : "hidden"
        if (open) {
          node.style.height = "auto"
        }
        if (!open && lazy) {
          setRenderChildren(false)
        }
        if (onComplete) {
          onComplete()
        }
      }
    }
    function handleTransitionEnd(event: TransitionEvent) {
      if (event.target === node && event.propertyName === "height") {
        handleComplete()
      }
    }
    if (instant || firstRender.current) {
      handleComplete()
      firstRender.current = false
    }

    node?.addEventListener("transitionend", handleTransitionEnd)
    return () => {
      node?.removeEventListener("transitionend", handleTransitionEnd)
    }
  }, [open])

  useLayoutEffect(() => {
    if (open) {
      openCollapse()
    }
  }, [renderChildren])

  return (
    <div
      ref={ref}
      style={{
        transition: instant || firstRender.current ? undefined : transition,
      }}
      {...restProps}
    >
      {renderChildren ? children : null}
    </div>
  )
}

export default Collapse
