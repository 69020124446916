import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import Container from "./Container"
// import { CarouselItem } from "./Carousel"

const StyledClients = styled.div`
  padding: 4rem 0 2rem;

  .clients {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(240px, 100%), 1fr));
    gap: 1rem;
  }

  h2 {
    color: ${props => props.theme.colors.primary};
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 0 0 1rem;
    font-size: 1.5rem;
  }

  .companyImage {
    padding: 1rem;

    img {
      width: 100%;
      height: auto;
      max-height: 3rem;
      object-fit: contain;
    }
  }
`

const clients = [
  {
    name: "IJsselvliet",
    logoSrc: "/logos/ijsselvliet.png",
  },
  {
    name: "Novaware",
    logoSrc: "/logos/novaware.svg",
  },
  {
    name: "IF-TV",
    logoSrc: "/logos/if-tv.png",
  },
]

function Clients() {
  return (
    <StyledClients>
      <Container>
        <h2>Deskie klanten</h2>
        <div className="clients">
          {clients.map((item, index) => (
            <div className="companyImage" key={index}>
              <img src={item.logoSrc} alt={item.name} />
            </div>
          ))}
        </div>
      </Container>
    </StyledClients>
  )
}

export default Clients
