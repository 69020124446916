import React from "react"
import Header from "../components/Header"
import Pricing from "../components/Pricing"
import PageWrapper from "../components/PageWrapper"
import Benefits from "../components/Benefits"
import Credits from "../components/Credits"
import Screenshots from "../components/Screenshots"
import Faq from "../components/Faq"
import Clients from "../components/Clients"
import PlanMeeting from "../components/PlanMeeting"

const Home = () => {
  return (
    <>
      <Header />
      <PageWrapper>
        <Benefits />
        <Credits />
        <Pricing />
        <Screenshots />
        <Clients />
        <Faq showTitle limit={5} />
        <PlanMeeting />
      </PageWrapper>
    </>
  )
}

export default Home
